import cx from 'classnames';
import { FormattedDate, useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import DownloadIcon from '../../../assets/icons/cloud-download.svg';
import CopyIcon from '../../../assets/icons/file-copy.svg';
import PdfIcon from '../../../assets/icons/pdf.svg';
import { FORMATTED_DATE_OPTIONS, copyToClipboard, pressReleasePropTypes } from '../../../utils';
import NotificationPopover from '../../Popovers/NotificationPopover';
import Tooltip from '../../Tooltip/Tooltip';
import styles from './pressReleases.module.scss';

const PressRelease = ({ title, description, fileUrl, fileName, date }) => {
	const intl = useIntl();
	const { locale } = intl;
	return (
		<li className={styles.pressRelease}>
			<div className={styles.pressReleaseContent}>
				<div className={styles.topBar}>
					<span>
						<FormattedDate value={new Date(date)} {...FORMATTED_DATE_OPTIONS} locale={locale} />
					</span>
					{fileUrl && (
						<div className={styles.buttonWrapper}>
							<a className={styles.downloadButton} href={fileUrl} download={fileName} target="_blank" rel="noreferrer">
								<PdfIcon className={cx(styles.downloadButtonIcon, styles.pdfIcon)} />
								<DownloadIcon className={cx(styles.downloadButtonIcon, styles.downloadIcon)} />
							</a>
							<NotificationPopover
								content={<p>{intl.formatMessage({ id: 'notification.copiedUrl' })}</p>}
								onClick={() => copyToClipboard(fileUrl)}
								className={styles.shareButton}>
								<Tooltip text={intl.formatMessage({ id: 'tooltip.copyUrl' })}>
									<CopyIcon />
								</Tooltip>
							</NotificationPopover>
						</div>
					)}
				</div>
				<h3>{title}</h3>
				{description && <p>{description}</p>}
			</div>
		</li>
	);
};

PressRelease.propTypes = pressReleasePropTypes;

export default PressRelease;
