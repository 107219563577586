import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { ContentLayout, QuickBottomNavigationWrapper } from '../../components/';
import Layout from '../../components/layout';
import PressReleasesList from '../../components/Pages/PressReleases/PressReleasesList';
import SEO from '../../components/seo';
import styles from '../../stylesheets/pages/page.module.scss';
import {
	QUICK_NAVIGATION_PRESS_RELEASES,
	getLocalizedDataFromContentfulEdges,
	getPressReleasesListData,
} from '../../utils';

const PressReleases = () => {
	const intl = useIntl();
	const { allContentfulPressRelease, allContentfulSpecificPageMetaData } = useStaticQuery(graphql`
		{
			allContentfulSpecificPageMetaData(filter: { pageKey: { eq: "press-releases" } }) {
				edges {
					node {
						pageKey
						title
						description
						node_locale
						previewImage {
							fixed(width: 1200, height: 630) {
								src
							}
						}
					}
				}
			}
			allContentfulPressRelease(sort: { fields: date, order: DESC }) {
				edges {
					node {
						node_locale
						title
						description {
							description
						}
						pdf {
							file {
								url
								fileName
							}
						}
						date
					}
				}
			}
		}
	`);
	const { node } = getLocalizedDataFromContentfulEdges(allContentfulSpecificPageMetaData.edges, intl.locale)[0];
	const pressReleases = getLocalizedDataFromContentfulEdges(allContentfulPressRelease.edges, intl.locale);
	const pressReleasesListData = getPressReleasesListData(pressReleases);
	return (
		<>
			<SEO lang={intl.locale} title={node.title} description={node.description}>
				<meta property="og:image" name="image" content={`http:${node.previewImage.fixed.src}`} />
			</SEO>
			<Layout>
				<QuickBottomNavigationWrapper optionalDataArray={QUICK_NAVIGATION_PRESS_RELEASES}>
					<ContentLayout>
						<div className={styles.newsroomPage}>
							<h1>{intl.formatMessage({ id: 'nav.press_releases' })}</h1>
							<PressReleasesList listData={pressReleasesListData} />
						</div>
					</ContentLayout>
				</QuickBottomNavigationWrapper>
			</Layout>
		</>
	);
};

export default PressReleases;
