import PropTypes from 'prop-types';
import React from 'react';

import { pressReleasePropTypes } from '../../../utils';
import PressRelease from './PressRelease';
import styles from './pressReleases.module.scss';

const PressReleasesList = ({ listData }) => {
	const renderPressRelease = (release, index) => {
		const { title, description, fileUrl, fileName, date } = release;
		return (
			<PressRelease
				key={`press-release-${index}`}
				title={title}
				description={description}
				fileUrl={fileUrl}
				fileName={fileName}
				date={date}
			/>
		);
	};

	return <ul className={styles.pressReleasesList}>{listData.map(renderPressRelease)}</ul>;
};

PressReleasesList.propTypes = {
	listData: PropTypes.arrayOf(PropTypes.shape(pressReleasePropTypes)),
};

export default PressReleasesList;
